
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class CountdownModal extends Vue {

    @Prop({
        default: null
    }) startDate: Date;

    created(){
    }

}
