
import { GameClient, QuestionAndAnswerClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import CountdownModal from '../modals/countdownModal.vue';

@Options({})
export default class LoggedUserLayout extends Vue {

    loaded: boolean = false;

    created() {
        Promise.all([
            QuestionAndAnswerClient.getQuestionsCount(),
            GameClient.getGameStart()
        ])
        .then(xs => {
            StorageServices.setQuestionsCount(xs[0]);

            this.$nextTick(() => {
                this.loaded = true;
            })

            if(!xs[1].canPlay) {
                this.$opModal.show(CountdownModal, {
                    startDate: new Date(xs[1].startDate)
                })
            }
        })
    }

    get isUrlChallenge() {
        return this.$route.fullPath.indexOf("challenge") > -1;
    }

}
